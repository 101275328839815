// A valid link should look like this, router path + search params, the param values can be changed:
// calibration-iodine-131/?m=553&calibrationDate=2022-10-17&deliveryDate=2022-10-13

// Import from library/framework
import { Form, useSearchParams } from "react-router-dom";
import { LineChart, XAxis, YAxis, Line, ResponsiveContainer, Tooltip } from 'recharts';
import { useState } from 'react';
import { motion, useMotionValue, AnimatePresence } from 'framer-motion';
import Draggable from 'react-draggable';
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import {
    Input,
    FormLabel,
    Heading,
    Flex,
    FormControl,
    Button,
    Container,
    Grid,
    GridItem,
    Box,
    Text,
    Switch,
} from "@chakra-ui/react";


// Takes title of page, calculation function for graph and productList to find closest match (product)
const CalibrationPage = ({ title, calculationConstant, productSizes, productCode }) => {
    // Function that returns datasheet for activity x days
    const dataGenerator = (activity, days) => {
        let data = [];
        for (let i = 0; i < days + 1; i++) {
            // Calculate activity needed
            const calibratedActivity = Math.round((((activity * 1000) * calculationConstant ** i) / 1000) * 100) / 100;
            
            // Our data points will be added to this variable later
            let dataPoint = {}
            
            // Variable to control percentage difference acceptance (in %/100):
            const variance = 1.02;

            // I think this is broken and probably unnecessary:
            // The % difference to closest size of calibrated activity:
            // const closestSizeDifference = ((getClosestSize(calibratedActivity, productSizes) / calibratedActivity) - 1) * 100;

            // if (calibratedActivity > (7400 * variance) || (closestSizeDifference > variance && calibratedActivity > 7400)) {
            if (calibratedActivity > (7400 * variance)) {
                // We need to calculate two new capsules that fulfill the activity requirement
                // We need a random number between 1000 and 6400 MBq (I believe we should not send capsules outside that range but this could be changed)
                const max = 6400;
                // This random number will either be the minimum required to fulfill the calibrated activity
                // So that we don't end up with a capsule to small to fill in the remaining (can't go over 7,4 GBq)
                // It also prevents any capsule from being lesser than 1 GBq (which is broken in our code (the capsule will say G555 instead of M555 for example) which is good for now)
                const min = calibratedActivity - 7400 < 1000 ? 1000 : calibratedActivity - 7400;
                const randomNumber = Math.random() * (max - min) + min;
                const remainingActivity = calibratedActivity - randomNumber;
                dataPoint = {
                    name: `${i} days`,
                    activity: calibratedActivity,
                    closestSize: getClosestSize(randomNumber, productSizes),
                    closestSizeTwo: getClosestSize(remainingActivity, productSizes)
                }
            } else {
                dataPoint = {
                    name: `${i} days`,
                    activity: calibratedActivity,
                    closestSize: getClosestSize(calibratedActivity, productSizes)
                }
            }

            data.push(dataPoint)
        }
        // The for loop first returns activity * 1 (calculationConstant to the power of 0 equals 1)
        // Then the loop returns the correct calibrated activity for the remaining X days
        return data;
        // We return the list of 1 + X days of calibrated activity 
    }

    // Function to find closest available size to calibrated size
    const getClosestSize = (needle, haystack) => {
        return haystack.reduce((a, b) => {
            let aDiff = Math.abs(a - needle);
            let bDiff = Math.abs(b - needle);

            if (aDiff === bDiff) {
                return a > b ? a : b;
            } else {
                return bDiff < aDiff ? b : a;
            }
        });
    }


    // Function to calculate difference between delivery date and calibration date
/*     const getDateDelta = (startDate, endDate, maxDelta) => {
        let count = 0;
        const curDate = new Date(startDate.getTime() + 1);
        while (curDate <= endDate) {
            count++;
            curDate.setDate(curDate.getDate() + 1);
        }
        if (count > maxDelta) {
            count = 0;
        }
        return count;
    } */


/*     const getDateDelta = (startDateString, endDateString, maxDelta) => {
        console.log(startDateString, endDateString);
        let count = 0;
        const startDate = new Date(startDateString);
        const endDate = new Date(endDateString);
        const curDate = new Date(startDate.getTime() - 60*60*1000);
        const startDateOffset = startDate.getTimezoneOffset() * 60 * 1000;
        const endDateOffset = endDate.getTimezoneOffset() * 60 * 1000;
      
        while (curDate <= endDate) {
          const curDateOffset = curDate.getTimezoneOffset() * 60 * 1000;
          const adjustedDate = new Date(curDate.getTime() + (curDateOffset - startDateOffset));
          if (adjustedDate <= endDate) {
            count++;
          }
          curDate.setDate(curDate.getDate() + 1);
        }
      
        if (count > maxDelta) {
          count = 0;
        }
      
        return count;
    } */

    const getDateDelta = (startDateString, endDateString, maxDelta) => {
        const startDate = new Date(startDateString);
        const endDate = new Date(endDateString);
      
        const delta = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));
      
        if (delta > maxDelta) {
          return 0;
        }
      
        return delta;
    }
      
    console.log(getDateDelta("2023-03-25", "2023-03-28", 10));

    // Manual Entry Functions
    const handleManualEntry = () => {
        if (deliveryDate && calibrationDate) {
            const offset = deliveryDate.getTimezoneOffset() - 60;
            let offsetDeliveryDate = new Date(deliveryDate.getTime() - (offset*60*1000));
            let offsetCalibrationDate = new Date(calibrationDate.getTime() - (offset*60*1000));
            // let offsetDeliveryDate = new Date(deliveryDate.getTime() - (60*60*1000));
            // let offsetCalibrationDate = new Date(calibrationDate.getTime() - (60*60*1000));
            
            // setSearchParams("?m=777&calibrationDate=2022-11-12&deliveryDate=2022-11-17");
            // let params = `?m=${event.target.activity}&deliveryDate=${event.target.delivery}&calibrationDate=${event.target.calibration}`;
            
            // Create our variables:
            const searchParamOffsetCalibrationDate = offsetCalibrationDate.toISOString().split('T')[0];
            const searchParamOffsetDeliveryDate = offsetDeliveryDate.toISOString().split('T')[0];

            // Create out params variable
            let params = `?m=${manualActivity}&deliveryDate=${searchParamOffsetDeliveryDate}&calibrationDate=${searchParamOffsetCalibrationDate}`;
            // Update the search url
            setSearchParams(params);
            // Set the new state:
            setActivity(manualActivity);
            setEndDate(new Date(searchParamOffsetCalibrationDate));
            setStartDate(new Date(searchParamOffsetDeliveryDate));
            // setDateDelta(getDateDelta(new Date(searchParamOffsetDeliveryDate), new Date(searchParamOffsetCalibrationDate), 10));
            setDateDelta(getDateDelta(searchParamOffsetDeliveryDate, searchParamOffsetCalibrationDate, 10));
            setData(dataGenerator(manualActivity, 10));
        }
    }

    const toggleManualEntry = () => {
        if (manualEntryDisplay === "none") {
            setManualEntryDisplay("flex");
        }
        else {
            setManualEntryDisplay("none");
        }
    }

    const handleManualActivityInput = (e) => {
        setManualActivity(e.target.value);
    }


    // Get url params for size
    const [searchParams, setSearchParams] = useSearchParams();

    // Save activity as variable 
    const [activity, setActivity] = useState(searchParams.get("m"))

    // Calibration date should always be after delivery date or be the same
    const [startDate, setStartDate] = useState(new Date(searchParams.get('deliveryDate')));
    const [endDate, setEndDate] = useState(new Date(searchParams.get("calibrationDate")));

    // Calibration date should always be after delivery date or be the same
    const [startDateString, setStartDateString] = useState(searchParams.get('deliveryDate'));
    const [endDateString, setEndDateString] = useState(searchParams.get("calibrationDate"));

    // Set our date delta state
    // const [dateDelta, setDateDelta] = useState(getDateDelta(startDate, endDate, 10));
    const [dateDelta, setDateDelta] = useState(getDateDelta(startDateString, endDateString, 10));

    // Create a dataset with activity for 10 days:
    const [data, setData] = useState(dataGenerator(activity, 10))

    // Manual entry stuff
    // Date pickers for manual entry window
    const [deliveryDate, setDeliveryDate] = useState();
    const [calibrationDate, setCalibrationDate] = useState();
    // Manual entry window activity
    const [manualActivity, setManualActivity] = useState("");
    // Manual entry toggle display 
    const [manualEntryDisplay, setManualEntryDisplay] = useState("none");

    return (
        <Box bgColor="rgba(255, 3, 133, 0.03)" py="8" className="manual-calibration-window-constraint">
            <Box>
                <Container maxW="700px">
                    <Box>
                        <Draggable
                            handle=".manual-calibration-handle"
                            bounds=".manual-calibration-window-constraint"
                        >
                            <Box position="absolute" zIndex={30}>
                            <AnimatePresence> 
                                <Box initial={{ scale: 0.2 }} animate={{ scale: 1 }} exit={{ opacity: 0, scale: 0.8 }} as={motion.div} key={manualEntryDisplay} display={manualEntryDisplay} border="2px" borderColor="pink.500"  boxShadow='dark-lg' p='8' rounded='md' bgColor='white' width="240px">
                                    <Box
                                    as={motion.div}
                                    whileHover={{ scale: 1.13 }}
                                    whileTap={{ scale: 1.32 }}
                                    className="manual-calibration-handle"
                                    w="48px"
                                    h="12px"
                                    bgColor="pink.500"
                                    position="absolute"
                                    top="-5px"
                                    left="96px"
                                    borderRadius="md"
                                    boxShadow='dark-lg'
                                    cursor="grab"
                                    ></Box>
                                    <Flex direction="column" gap="4">
                                        <Heading size="md" textAlign="center">Manual entry</Heading>
                                        <FormControl colorScheme="purple">
                                            <FormLabel>Activity (MBq)</FormLabel>
                                            <Input value={manualActivity} onChange={(e) => handleManualActivityInput(e)} type="number" bg="white" placeholder="Activity (MBq)" focusBorderColor="pink.500"></Input>
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Delivery Date</FormLabel>
                                            <SingleDatepicker
                                                name="delivery-date-input"
                                                date={deliveryDate}
                                                onDateChange={(date) => setDeliveryDate(date)}
                                                configs={{
                                                    dateFormat: 'yyyy-MM-dd'
                                                }}
                                                propsConfigs={{
                                                    dateNavBtnProps: {
                                                        colorScheme: "white",
                                                        variant: "outline"
                                                    },
                                                    dayOfMonthBtnProps: {
                                                        defaultBtnProps: {
                                                            borderColor: "pink.500",
                                                            _hover: {
                                                                background: 'pink.500',
                                                            }
                                                        },
                                                        isInRangeBtnProps: {
                                                            color: "yellow",
                                                        },
                                                        selectedBtnProps: {
                                                            background: "pink.500",
                                                            color: "white",
                                                            // borderColor: "curiumActivePink",
                                                        },
                                                        todayBtnProps: {
                                                            // background: "curiumPink",
                                                            borderColor: "purple.500",
                                                            borderWidth: "2.5px",
                                                            variant: "outline",
                                                        }   
                                                    },
                                                    inputProps: {
                                                        size: "md",
                                                        bg: "white",
                                                        placeholder: "Delivery Date",
                                                        focusBorderColor: "pink.500",
                                                    },
                                                    popoverCompProps: {
                                                        popoverContentProps: {
                                                            bg: "#333",
                                                            color: "white",
                                                        },
                                                    },
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel>Calibration Date</FormLabel>
                                            <SingleDatepicker
                                                name="calibration-date-input"
                                                date={calibrationDate}
                                                onDateChange={(date) => setCalibrationDate(date)}
                                                configs={{
                                                    dateFormat: 'yyyy-MM-dd',
                                                }}
                                                propsConfigs={{
                                                    dateNavBtnProps: {
                                                        colorScheme: "white",
                                                        variant: "outline"
                                                    },
                                                    dayOfMonthBtnProps: {
                                                        defaultBtnProps: {
                                                            borderColor: "pink.500",
                                                            _hover: {
                                                                background: 'pink.500',
                                                            }
                                                        },
                                                        isInRangeBtnProps: {
                                                            color: "yellow",
                                                        },
                                                        selectedBtnProps: {
                                                            background: "pink.500",
                                                            color: "white",
                                                            // borderColor: "curiumActivePink",
                                                        },
                                                        todayBtnProps: {
                                                            // background: "curiumPink",
                                                            borderColor: "purple.500",
                                                            borderWidth: "2.5px",
                                                            variant: "outline",
                                                        }
                                                    },
                                                    inputProps: {
                                                        size: "md",
                                                        bg: "white",
                                                        placeholder: "Calibration Date",
                                                        focusBorderColor: "pink.500",
                                                    },
                                                    popoverCompProps: {
                                                        popoverContentProps: {
                                                            bg: "#333",
                                                            color: "white",
                                                        },
                                                    },
                                                }}
                                            />
                                        </FormControl>
                                        <Button
                                            onClick={() => handleManualEntry()}
                                            variant="solid"
                                            color="white"        
                                            colorScheme="pink"
                                        >
                                            Update
                                        </Button>
                                    </Flex>
                                </Box>
                            </AnimatePresence>
                            </Box>
                        </Draggable>
                        <Heading my="6">{title}</Heading>
                        <Text fontSize="lg" fontWeight="bold">{`Delivery day: ${startDate.toISOString().split('T')[0]}`}</Text>
                        <Text fontSize="lg" fontWeight="bold">{`Calibration day: ${endDate.toISOString().split('T')[0]}`}</Text>
                        <Text fontSize="lg" fontWeight="bold">{`Activity (MBq): ${activity}`}</Text>
                        <Text fontSize="xl" mt="4">{`It looks like you should calibrate for ${dateDelta} days, the `} 
                            <Grid display="inline-grid" templateColumns='1fr' templateRows='1fr'>
                                <GridItem marginTop="10px" colStart={0} rowStart={0} colEnd={1} rowEnd={1}>
                                    <Text userSelect="all" color="pink.500" fontWeight="bold" whitespace-nowrap as={motion.span} initial={{ y: "-200px", opacity: 0 }} animate={{ y: "0px", opacity: 1 }} key={data[dateDelta].activity} zIndex={10} position="absolute" px="2" borderRadius="md" border="2px" bgColor="white" borderColor="pink.500">{`${productCode}-${data[dateDelta].activity < 1000 ? "M" : "G"}${data[dateDelta].closestSize < 1000 ? data[dateDelta].closestSize : data[dateDelta].closestSize / 1000}`}</Text>
                                </GridItem>
                                <GridItem colStart={0} rowStart={0} colEnd={1} rowEnd={1}>
                                    <Text userSelect="all" color="pink.500" fontWeight="bold" whitespace-nowrap zIndex={0} opacity="0" px="2" borderRadius="md" border="2px" borderColor="pink.500" bgColor="white">{`${productCode}-${data[dateDelta].activity < 1000 ? "M" : "G"}${data[dateDelta].closestSize < 1000 ? data[dateDelta].closestSize : data[dateDelta].closestSize / 1000}`}</Text>
                                </GridItem>
                            </Grid>{data[dateDelta].closestSizeTwo ? " capsule and the " : " capsule."}{data[dateDelta].closestSizeTwo ? 
                            <Grid display="inline-grid" templateColumns='1fr' templateRows='1fr'>
                                <GridItem marginTop="10px" colStart={0} rowStart={0} colEnd={1} rowEnd={1}>
                                    <Text userSelect="all" color="pink.500" fontWeight="bold" whitespace-nowrap as={motion.span} initial={{ y: "-200px", opacity: 0 }} animate={{ y: "0px", opacity: 1 }} key={data[dateDelta].activity} zIndex={10} position="absolute" px="2" borderRadius="md" border="2px" borderColor="pink.500" bgColor="white">{`${productCode}-${data[dateDelta].activity < 1000 ? "M" : "G"}${data[dateDelta].closestSizeTwo < 1000 ? data[dateDelta].closestSizeTwo : data[dateDelta].closestSizeTwo / 1000}`}</Text>
                                </GridItem>
                                <GridItem colStart={0} rowStart={0} colEnd={1} rowEnd={1}>
                                    <Text userSelect="all" color="pink.500" fontWeight="bold" whitespace-nowrap borderRadius="md" px="2" zIndex={0} border="2px" borderColor="pink.500" bgColor="white" opacity="0">{`${productCode}-${data[dateDelta].activity < 1000 ? "M" : "G"}${data[dateDelta].closestSizeTwo < 1000 ? data[dateDelta].closestSizeTwo : data[dateDelta].closestSizeTwo / 1000}`}</Text>
                                </GridItem>
                            </Grid> : null}{data[dateDelta].closestSizeTwo ? " capsule." : null}
                        </Text>
                        <Text mb="4" fontSize="xl">(Assuming there are no holidays)</Text>
                        <FormControl paddingTop="1rem" display='flex' alignItems='center'>
                            <FormLabel htmlFor='manual-entry' mb='0'>
                                <Text fontSize="lg">Show manual entry window?</Text>
                            </FormLabel>
                            <Switch colorScheme="pink" size="md" onChange={() => toggleManualEntry()} id='manual-entry' />
                        </FormControl>
                        <Box bgColor="white" color="black" p="8" my="4" borderRadius="md" shadow="md" border="2px" borderColor="pink.500">
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart
                                    // width={500} 
                                    // height={300} 
                                    data={data}
                                    margin={{
                                        top: 8,
                                        right: 15,
                                        left: 15,
                                        bottom: 30
                                    }}
                                >
                                    <Tooltip />
                                    <XAxis interval={0} textAnchor="end" angle={-90} dataKey="name" />
                                    <YAxis hide="true" dataKey="activity" domain={["dataMin", "dataMax"]} />
                                    {/* <CartesianGrid stroke="#eee" strokeDasharray="5 5"/> */}
                                    <Line type="monotone" dataKey="activity" stroke="#e71f89" activeDot={{ r: 8 }} />
                                </LineChart>
                            </ResponsiveContainer>
                        </Box>
                        <Flex gap="2" flexDirection="column">
                            <Text fontSize="lg" my="4">Closest capsule to pick for each day:</Text>
                            {/* <p>0 days: <span className="select-all px-2 rounded-md border-2  hover:bg-curium-active whitespace-nowrap">{`${productCode}-${data[0].activity < 1000 ? "M" : "G"}${data[0].closestSize < 1000 ? data[0].closestSize : data[0].closestSize / 1000}`}</span>{data[0].closestSizeTwo ? " & " : null}{data[0].closestSizeTwo ? <span className="select-all px-2 rounded-md border-2  hover:bg-curium-active whitespace-nowrap">{`${productCode}-${data[0].activity < 1000 ? "M" : "G"}${data[0].closestSizeTwo < 1000 ? data[0].closestSizeTwo : data[0].closestSizeTwo / 1000}`}</span> : null} - Activity (MBq): {data[0].closestSize + (data[0].closestSizeTwo ? data[0].closestSizeTwo : 0)} / Activity (GBq): {(data[0].closestSize + (data[0].closestSizeTwo ? data[0].closestSizeTwo : 0))/1000}</p>
                    <p>1 days: <span className="select-all px-2 rounded-md border-2  hover:bg-curium-active whitespace-nowrap">{`${productCode}-${data[1].activity < 1000 ? "M" : "G"}${data[1].closestSize < 1000 ? data[1].closestSize : data[1].closestSize / 1000}`}</span>{data[1].closestSizeTwo ? " & " : null}{data[1].closestSizeTwo ? <span className="select-all px-2 rounded-md border-2  hover:bg-curium-active whitespace-nowrap">{`${productCode}-${data[1].activity < 1000 ? "M" : "G"}${data[1].closestSizeTwo < 1000 ? data[1].closestSizeTwo : data[1].closestSizeTwo / 1000}`}</span> : null} - Activity (MBq): {data[1].closestSize + (data[1].closestSizeTwo ? data[1].closestSizeTwo : 0)} / Activity (GBq): {(data[1].closestSize + (data[1].closestSizeTwo ? data[1].closestSizeTwo : 0))/1000}</p>
                    <p>2 days: <span className="select-all px-2 rounded-md border-2  hover:bg-curium-active whitespace-nowrap">{`${productCode}-${data[2].activity < 1000 ? "M" : "G"}${data[2].closestSize < 1000 ? data[2].closestSize : data[2].closestSize / 1000}`}</span>{data[2].closestSizeTwo ? " & " : null}{data[2].closestSizeTwo ? <span className="select-all px-2 rounded-md border-2  hover:bg-curium-active whitespace-nowrap">{`${productCode}-${data[2].activity < 1000 ? "M" : "G"}${data[2].closestSizeTwo < 1000 ? data[2].closestSizeTwo : data[2].closestSizeTwo / 1000}`}</span> : null} - Activity (MBq): {data[2].closestSize + (data[2].closestSizeTwo ? data[2].closestSizeTwo : 0)} / Activity (GBq): {(data[2].closestSize + (data[2].closestSizeTwo ? data[2].closestSizeTwo : 0))/1000}</p>
                    <p>3 days: <span className="select-all px-2 rounded-md border-2  hover:bg-curium-active whitespace-nowrap">{`${productCode}-${data[3].activity < 1000 ? "M" : "G"}${data[3].closestSize < 1000 ? data[3].closestSize : data[3].closestSize / 1000}`}</span>{data[3].closestSizeTwo ? " & " : null}{data[3].closestSizeTwo ? <span className="select-all px-2 rounded-md border-2  hover:bg-curium-active whitespace-nowrap">{`${productCode}-${data[3].activity < 1000 ? "M" : "G"}${data[3].closestSizeTwo < 1000 ? data[3].closestSizeTwo : data[3].closestSizeTwo / 1000}`}</span> : null} - Activity (MBq): {data[3].closestSize + (data[3].closestSizeTwo ? data[3].closestSizeTwo : 0)} / Activity (GBq): {(data[3].closestSize + (data[3].closestSizeTwo ? data[3].closestSizeTwo : 0))/1000}</p>
                    <p>4 days: <span className="select-all px-2 rounded-md border-2  hover:bg-curium-active whitespace-nowrap">{`${productCode}-${data[4].activity < 1000 ? "M" : "G"}${data[4].closestSize < 1000 ? data[4].closestSize : data[4].closestSize / 1000}`}</span>{data[4].closestSizeTwo ? " & " : null}{data[4].closestSizeTwo ? <span className="select-all px-2 rounded-md border-2  hover:bg-curium-active whitespace-nowrap">{`${productCode}-${data[4].activity < 1000 ? "M" : "G"}${data[4].closestSizeTwo < 1000 ? data[4].closestSizeTwo : data[4].closestSizeTwo / 1000}`}</span> : null} - Activity (MBq): {data[4].closestSize + (data[4].closestSizeTwo ? data[4].closestSizeTwo : 0)} / Activity (GBq): {(data[4].closestSize + (data[4].closestSizeTwo ? data[4].closestSizeTwo : 0))/1000}</p>
                    <p>5 days: <span className="select-all px-2 rounded-md border-2  hover:bg-curium-active whitespace-nowrap">{`${productCode}-${data[5].activity < 1000 ? "M" : "G"}${data[5].closestSize < 1000 ? data[5].closestSize : data[5].closestSize / 1000}`}</span>{data[5].closestSizeTwo ? " & " : null}{data[5].closestSizeTwo ? <span className="select-all px-2 rounded-md border-2  hover:bg-curium-active whitespace-nowrap">{`${productCode}-${data[5].activity < 1000 ? "M" : "G"}${data[5].closestSizeTwo < 1000 ? data[5].closestSizeTwo : data[5].closestSizeTwo / 1000}`}</span> : null} - Activity (MBq): {data[5].closestSize + (data[5].closestSizeTwo ? data[5].closestSizeTwo : 0)} / Activity (GBq): {(data[5].closestSize + (data[5].closestSizeTwo ? data[5].closestSizeTwo : 0))/1000}</p>
                    <p>6 days: <span className="select-all px-2 rounded-md border-2  hover:bg-curium-active whitespace-nowrap">{`${productCode}-${data[6].activity < 1000 ? "M" : "G"}${data[6].closestSize < 1000 ? data[6].closestSize : data[6].closestSize / 1000}`}</span>{data[6].closestSizeTwo ? " & " : null}{data[6].closestSizeTwo ? <span className="select-all px-2 rounded-md border-2  hover:bg-curium-active whitespace-nowrap">{`${productCode}-${data[6].activity < 1000 ? "M" : "G"}${data[6].closestSizeTwo < 1000 ? data[6].closestSizeTwo : data[6].closestSizeTwo / 1000}`}</span> : null} - Activity (MBq): {data[6].closestSize + (data[6].closestSizeTwo ? data[6].closestSizeTwo : 0)} / Activity (GBq): {(data[6].closestSize + (data[6].closestSizeTwo ? data[6].closestSizeTwo : 0))/1000}</p>
                    <p>7 days: <span className="select-all px-2 rounded-md border-2  hover:bg-curium-active whitespace-nowrap">{`${productCode}-${data[7].activity < 1000 ? "M" : "G"}${data[7].closestSize < 1000 ? data[7].closestSize : data[7].closestSize / 1000}`}</span>{data[7].closestSizeTwo ? " & " : null}{data[7].closestSizeTwo ? <span className="select-all px-2 rounded-md border-2  hover:bg-curium-active whitespace-nowrap">{`${productCode}-${data[7].activity < 1000 ? "M" : "G"}${data[7].closestSizeTwo < 1000 ? data[7].closestSizeTwo : data[7].closestSizeTwo / 1000}`}</span> : null} - Activity (MBq): {data[7].closestSize + (data[7].closestSizeTwo ? data[7].closestSizeTwo : 0)} / Activity (GBq): {(data[7].closestSize + (data[7].closestSizeTwo ? data[7].closestSizeTwo : 0))/1000}</p>
                    <p>8 days: <span className="select-all px-2 rounded-md border-2  hover:bg-curium-active whitespace-nowrap">{`${productCode}-${data[8].activity < 1000 ? "M" : "G"}${data[8].closestSize < 1000 ? data[8].closestSize : data[8].closestSize / 1000}`}</span>{data[8].closestSizeTwo ? " & " : null}{data[8].closestSizeTwo ? <span className="select-all px-2 rounded-md border-2  hover:bg-curium-active whitespace-nowrap">{`${productCode}-${data[8].activity < 1000 ? "M" : "G"}${data[8].closestSizeTwo < 1000 ? data[8].closestSizeTwo : data[8].closestSizeTwo / 1000}`}</span> : null} - Activity (MBq): {data[8].closestSize + (data[8].closestSizeTwo ? data[8].closestSizeTwo : 0)} / Activity (GBq): {(data[8].closestSize + (data[8].closestSizeTwo ? data[8].closestSizeTwo : 0))/1000}</p>
                    <p>9 days: <span className="select-all px-2 rounded-md border-2  hover:bg-curium-active whitespace-nowrap">{`${productCode}-${data[9].activity < 1000 ? "M" : "G"}${data[9].closestSize < 1000 ? data[9].closestSize : data[9].closestSize / 1000}`}</span>{data[9].closestSizeTwo ? " & " : null}{data[9].closestSizeTwo ? <span className="select-all px-2 rounded-md border-2  hover:bg-curium-active whitespace-nowrap">{`${productCode}-${data[9].activity < 1000 ? "M" : "G"}${data[9].closestSizeTwo < 1000 ? data[9].closestSizeTwo : data[9].closestSizeTwo / 1000}`}</span> : null} - Activity (MBq): {data[9].closestSize + (data[9].closestSizeTwo ? data[9].closestSizeTwo : 0)} / Activity (GBq): {(data[9].closestSize + (data[9].closestSizeTwo ? data[9].closestSizeTwo : 0))/1000}</p>
                    <p>10 days: <span className="select-all px-2 rounded-md border-2  hover:bg-curium-active whitespace-nowrap">{`${productCode}-${data[10].activity < 1000 ? "M" : "G"}${data[10].closestSize < 1000 ? data[10].closestSize : data[10].closestSize / 1000}`}</span>{data[10].closestSizeTwo ? " & " : null}{data[10].closestSizeTwo ? <span className="select-all px-2 rounded-md border-2  hover:bg-curium-active whitespace-nowrap">{`${productCode}-${data[10].activity < 1000 ? "M" : "G"}${data[10].closestSizeTwo < 1000 ? data[10].closestSizeTwo : data[10].closestSizeTwo / 1000}`}</span> : null} - Activity (MBq): {data[10].closestSize + (data[10].closestSizeTwo ? data[10].closestSizeTwo : 0)} / Activity (GBq): {(data[10].closestSize + (data[10].closestSizeTwo ? data[10].closestSizeTwo : 0))/1000}</p> */}
                            {Array.from(Array(11).keys()).map((day) => (
                                <Box key={day} shadow="sm" padding="1rem" mt="5px" borderRadius="lg" bg="white" border="1px" borderColor="gray.50">
                                    <Text>{day} days: <Text display="inline-block" width="max" userSelect="all" border="1px" borderColor="gray.700" color="gray.700" whitespace="nowrap" px="2" bgColor="white" borderRadius="md">{`${productCode}-${data[day].activity < 1000 ? "M" : "G"}${data[day].closestSize < 1000 ? data[day].closestSize : data[day].closestSize / 1000}`}</Text>{data[day].closestSizeTwo ? " & " : null}{data[day].closestSizeTwo ? <span className="select-all px-2 border-[1.5px] border-white rounded-md hover:bg-curium-active whitespace-nowrap">{`${productCode}-${data[day].activity < 1000 ? "M" : "G"}${data[day].closestSizeTwo < 1000 ? data[day].closestSizeTwo : data[day].closestSizeTwo / 1000}`}</span> : null}</Text>
                                    <Text>Activity (MBq): {data[day].closestSize + (data[day].closestSizeTwo ? data[day].closestSizeTwo : 0)} / Activity (GBq): {(data[day].closestSize + (data[day].closestSizeTwo ? data[day].closestSizeTwo : 0)) / 1000}</Text>
                                </Box>
                            ))}
                        </Flex>
                    </Box>
                </Container>
            </Box>
        </Box>
    );
}

export default CalibrationPage