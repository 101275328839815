import { useState, Fragment } from 'react'

import {
    Box,
    Flex,
    Heading,
    Input,
} from '@chakra-ui/react'

import SiteCard from "../SiteCard"


const HomePage = () => {
    const [searchTerm, setSearchTerm] = useState("")
    const handleSearch = (event) => setSearchTerm(event.target.value)

    return (
        <Fragment>
            <Box
                bgImage="url('/assets/curium-background.jpeg')"
                bgPosition="center"
                bgRepeat="no-repeat"
                bgSize="cover"
                h="450px"
            >
                <Flex direction={'column'} maxW={["280px"]} h="450px" justify="center" marginLeft={["0", "100px", "120px", "20vw"]} margin={["auto"]}>
                    <Heading>Find your CEOS:</Heading>
                    <Input 
                        placeholder='Automatic search' 
                        focusBorderColor="#e71f89" 
                        marginTop={4}
                        bgColor="white"
                        borderColor="gray"
                        value={searchTerm}
                        onChange={e => handleSearch(e)}
                    />
                </Flex>
            </Box>
            <Box>
                <Flex py={8} gap={8} marginX={["auto", "100px", "120px", "20vw"]} wrap={'wrap'} justify={["center", "initial"]}>
                    <SiteCard searchWord={searchTerm} link="https://curiumnordic.com/" flag="se" names={["Sweden", "Sverige", "SE"]}/>
                    <SiteCard searchWord={searchTerm} link="https://curiumexpressorderingsystem.com/denmark" flag="dk" names={["Denmark", "Danmark", "DK"]}/>
                    <SiteCard searchWord={searchTerm} link="https://curiumexpressorderingsystem.com/germany" flag="de" names={["Germany", "Deutschland", "DE"]}/>
                    <SiteCard searchWord={searchTerm} link="#" flag="no" names={["Norway", "Norge", "NO"]}/>
                </Flex>
            </Box>
        </Fragment>
    )
}


export default HomePage