import { Global } from '@emotion/react'

const Fonts = () => (
  <Global
    styles={`
    @font-face {
        font-family: 'Planer Heading';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('../fonts/Planer-Regular.ttf') format('truetype');
    }
    @font-face {
        font-family: 'Planer Text';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('../fonts/Planer-Regular.ttf') format('truetype');
    }
      `}
  />
)

export default Fonts