import { AnimatePresence, motion } from 'framer-motion';

import {
    Card,
    CardBody,
    CardFooter,
    Divider,
    ButtonGroup,
    Button,
    Image,
} from '@chakra-ui/react';
import {
    ArrowForwardIcon
} from '@chakra-ui/icons'


const SiteCard = ({searchWord, link, flag, names}) => {
    // Function to open the link in a new tab when the user clicks on the container.
    const handleClick = () => {
        // window.open(link, '_blank');
        window.open(link, "_self");
    }

    let displaySite;

    // If searchWord is empty, display all sites
    if (searchWord !== '') {
        let hits = 0;
        for (let i = 0; i < names.length; i++) {
            // If expression is true that means search word matches a string in the names array.
            // We increment hits by 1.
            if (names[i].toLowerCase().includes(searchWord.toLowerCase())) {
                hits += 1;
            }
        }
        // If hits are more than 1 we display the site.
        // To do this we set displaySite to true.
        if (hits > 0) {
            displaySite = true;
        } 
        else {
            displaySite = false;
        }
    } 
    else {
        displaySite = true;
    }

    if (displaySite) {
        return (
            <AnimatePresence>
            <motion.div whileHover={{scale: 1.03}} whileTap={{scale: 1.05}} key={flag} transition={{ duration: 0.15, bounceStiffness: 100, bounceDamping: 100, scale: {duration: 0.08} }} initial={{ scale: 0.5, opacity: 0, x: "-000px" }} animate={{ scale: 1, opacity: 1, x: 0 }} exit={{ scale: 0.8, opacity: 0, x: "000px" }} onClick={handleClick}>
                <Card maxW='sm' bg="gray.50">
                    <CardBody>
                        <Image
                        src='./assets/ceos-logo.png'
                        alt='curium express ordering system logo'
                        borderRadius='lg'
                        w="200px"
                        />
                    </CardBody>
                    <Divider />
                    <CardFooter>
                        <ButtonGroup spacing='2'>
                        <Image
                        src={"./assets/flags/" + flag + ".svg"}
                        alt={flag + " flag"}
                        borderRadius='md'
                        h="40px"
                        />
                        <a href={link} target="_self" rel="noopener noreferrer">
                            <Button variant='outline' colorScheme='pink'>
                                Go to site <ArrowForwardIcon mt="3px" ml="3px"/>
                            </Button>
                        </a>
                        </ButtonGroup>
                    </CardFooter>
                </Card>
            </motion.div>
            </AnimatePresence>
        );
    }
    else {
        // return null;
        return (
            <AnimatePresence>

            </AnimatePresence>
        );
    }
}

export default SiteCard;