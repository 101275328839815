import {
    Center,
    Box,
    Text,
} from '@chakra-ui/react'


const Footer = () => {
    return (
        <Center bg='white' h='100px' color='white'>
            <Text
            bgGradient='linear(to-l, #e71f89, #1f0094)'
            bgClip='text'
            fontSize={['xl', '2xl', '3xl']}
            fontWeight='extrabold'
            >
                Curium Express Ordering System
            </Text>
        </Center>
    )
}


export default Footer