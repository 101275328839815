import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
    config: {
        useSystemColorMode: false,
        initialColorMode: "light",
      },
    fonts: {
        heading: `'Planer Heading', Andale Mono, Courier, Courier New, Avant Garde, Arial, sans-serif`,
        body: `'Planer Text', Andale Mono, Courier, Courier New, Avant Garde, Arial, sans-serif`,
      },
})

export default theme;