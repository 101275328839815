// Import Chakra UI Provider
import { ChakraProvider } from '@chakra-ui/react'

// Import react router stuff
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';

// Import the Chakra UI theme and the Font componenet
import Fonts from './fonts'
import theme from './theme'

// Import components
import HomePage from './components/HomePage';
import CalibrationPage from './components/CalibrationPage';



import Nav from './components/Nav'
import Footer from './components/Footer'


function App() {
  const capsuleStandardSizes = [
    37, 40, 45, 50, 55, 55.5, 60, 65, 70, 74, 75, 80, 81, 85, 90, 92.5, 95, 100, 105, 110, 111, 115, 120, 122, 125, 129.5, 130, 135, 140, 145, 148, 150, 155, 160, 165, 166.5, 170, 175, 180, 185, 190, 195, 200, 203.5, 210, 215, 220, 222, 225, 230, 235, 240, 240.5, 245, 250, 259, 260, 270, 277.5, 280, 290, 296, 300, 310, 314.5, 320, 322, 330, 333, 340, 350, 351.5, 360, 370, 380, 385, 388.5, 390, 400, 403, 407, 410, 420, 425.5, 430, 440, 444, 450, 460, 462.5, 470, 477, 479, 480, 481, 490, 499.5, 500, 518, 536.5, 555, 573.5, 592, 600, 610.5, 629, 647.5, 655, 666, 684.5, 700, 703, 721.5, 740, 758.5, 777, 795.5, 800, 814, 832.5, 851, 869.5, 888, 900, 906.5, 925, 943.5, 962, 980.5, 999, 
    1000, 1017.5, 1036, 1054.5, 1073, 1091.5, 1100, 1110, 1128.5, 1147, 1165.5, 1184, 1202.5, 1221, 1239.5, 1258, 1276.5, 1295, 1313.5, 1332, 1350.5, 1369, 1387.5, 1406, 1424.5, 1443, 1461.5, 1480, 1498.5, 1517, 1535.5, 1554, 1572.5, 1591, 1609.5, 1628, 1646.5, 1665, 1683.5, 1695, 1702, 1720.5, 1739, 1757.5, 1776, 1794.5, 1813, 1831.5, 1850, 1868.5, 1887, 1905.5, 1924, 1942.5, 1961, 1979.5, 1998, 2000, 2035, 2072, 2109, 2146, 2183, 2220, 2257, 2294, 2331, 2368, 2405, 2442, 2479, 2516, 2553, 2590, 2627, 2664, 2701, 2738, 2775, 2812, 2849, 2886, 2923, 2960, 2997, 3000, 
    3034, 3071, 3108, 3145, 3182, 3219, 3256, 3293, 3330, 3367, 3404, 3441, 3478, 3515, 3552, 3589, 3626, 3663, 3700, 3737, 3774, 3811, 3848, 3885, 3922, 3959, 3996, 4000, 4033, 4035, 407, 4107, 4144, 4181, 4218, 4255, 4292, 4329, 4366, 4403, 4440, 4477, 4514, 4551, 4773, 4795, 4810, 4847, 4884, 4921, 4958, 4995, 5000, 5032, 5069, 5106, 5143, 5180, 5217, 5225, 5254, 5291, 5328, 5365, 5402, 5439, 5476, 5513, 5550, 5587, 5624, 5661, 5698, 5735, 5772, 5809, 5846, 5883, 5920, 5957, 5994, 6000, 6031, 605, 6068, 6105, 6142, 6179, 6216, 6253, 6290, 6327, 6364, 6401, 6438, 6475, 6512, 6549, 6586, 6623, 6660, 6697, 6734, 6771, 6808, 6845, 6882, 6919, 6956, 6993, 7000, 7030, 7067, 7104, 7141, 7178, 7215, 7252, 7289, 7326, 7363, 7400,
  ]

  return (
    // Wrap the app in ChakraUI Provider
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <Fonts />
        <Nav />
        <Routes>
          <Route path="/*" element={<HomePage />} />
          <Route path="/calibration-iodine-131/*" element={<CalibrationPage title="Calibration of I-131 Therapy Capsules" calculationConstant={1.088} productCode="DRN5302" productSizes={capsuleStandardSizes}/>} />
        </Routes>
        <Footer />
      </ChakraProvider>
    </BrowserRouter>
  );
}

export default App;
